import { createSelector } from "reselect";
import {
  ANALYSIS_REQUEST_NAMESPACE,
  ANALYSIS_REAGENT_PROVIDER_NAMESPACE,
  ANALYSIS_REQUEST_CATEGORY_NAMESPACE,
  ANALYSIS_RESULT_GROUP_NAMESPACE,
  ANALYSIS_REQUEST_CODE_NAMESPACE,
} from "../actions/analysis-request";
import { RootState } from "../reducers/root";
import {
  AnalysisReagentProvider,
  AnalysisRequest,
  AnalysisRequestCategory,
  AnalysisRequestCode,
  AnalysisRequestState,
} from "../types/shared/v2/AnalysisRequest";
import { ObjectHeader, PrivacyDomain, StoreObject } from "../util/objects";
import {
  AnalysisResultGroup,
  AnalysisResultGroupType,
} from "../types/shared/v2/AnalysisResultGroup";
import { selectObjectByAttr } from "./objects";
import { WorklistV2 } from "../types/shared/v2/Worklist";

export const selectAnalysisRequestIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequest> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};

export const selectAnalysisRequestObjects = createSelector(
  [selectAnalysisRequestIndexedObjects],
  (objects) => {
    const analysisRequests: StoreObject<AnalysisRequest>[] = [];
    if (!objects) return analysisRequests;
    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, analysisRequests);
  }
);

export const selectAnalysisRequests = createSelector(
  [selectAnalysisRequestObjects],
  (objects) => {
    const analysisRequests: AnalysisRequest[] = [];
    if (!objects) return analysisRequests;
    return objects.map(obj => obj.data)
  }
);


export const selectTakenIntoAccountAnalysisRequests = createSelector(
  [selectAnalysisRequests],
  (analysisRequests) => {
    return analysisRequests.filter((r) => {
      const analysisRequestCodes = Object.keys(
        r.types
      ) as string[];

      const isPending =
        analysisRequestCodes.some(
          (typ) => r.types[typ] === AnalysisRequestState.TakenIntoAccount
        )

      return isPending
    })
  }
);
export const selectPendingAnalysisRequests = createSelector(
  [selectAnalysisRequests],
  (analysisRequests) => {
    return analysisRequests.filter((r) => {
      const analysisRequestCodes = Object.keys(
        r.types
      ) as string[];

      const isPending =
        analysisRequestCodes.some(
          (typ) => ([AnalysisRequestState.TakenIntoAccount, AnalysisRequestState.IntegratedWorklist].includes(r.types[typ]))
        )

      return isPending
    })
  }
);


export const selectPatientPendingAnalysisRequests = createSelector(
  [
    selectPendingAnalysisRequests,
    (state: RootState, inlogId: string) => inlogId,
  ],
  (pendingAnalyses, inlogId) => {
    const analysisRequest: AnalysisRequest[] = [];
    if (!pendingAnalyses) return analysisRequest;

    return Object.values(pendingAnalyses).reduce((memo, pending) => {
      if (pending.ref.patient.inlogId !== inlogId) return memo;
      memo.push(pending);
      return memo;
    }, analysisRequest);
  }
);

export const selectAnalysisReagentProviderIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisReagentProvider> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REAGENT_PROVIDER_NAMESPACE]
    ?.byDomain[PrivacyDomain.Public]?.byId;
};

export const selectAnalysisReagentProviderObjects = createSelector(
  [selectAnalysisReagentProviderIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisReagentProvider>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectAnalysisReagentProviders = createSelector(
  [selectAnalysisReagentProviderObjects],
  (objects) => {
    return objects.map((obj) => obj.data);
  }
);

export const selectAnalysisReagentProviderByCode = (
  state: RootState,
  code: string
) => {
  return selectObjectByAttr<AnalysisReagentProvider>(
    state,
    ANALYSIS_REAGENT_PROVIDER_NAMESPACE,
    PrivacyDomain.Public,
    "code",
    code
  );
};

export const selectAnalysisRequestCategoryIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequestCategory> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_CATEGORY_NAMESPACE]
    ?.byDomain[PrivacyDomain.Public]?.byId;
};

export const selectAnalysisRequestCategoryObjects = createSelector(
  [selectAnalysisRequestCategoryIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisRequestCategory>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectAnalysisRequestCategories = createSelector(
  [selectAnalysisRequestCategoryObjects],
  (objects) => {
    return objects.map((obj) => obj.data)
      .sort((arc1, arc2) => {
        if (arc1.code > arc2.code) return 1;
        if (arc1.code < arc2.code) return -1;
        return 0;
      });
  }
);

export const selectAnalysisRequestCategoriesByReagent = createSelector([
  (state: RootState, reagentProviderCode: string) => selectAnalysisRequestCodeByReagent(state, reagentProviderCode),
  selectAnalysisRequestCategories,
], (analysisRequestCodes, analysisRequestCategories) => {
  return analysisRequestCategories
    .filter(arc => analysisRequestCodes?.some(analysisRequestCode => analysisRequestCode.ref.analysisRequestCategory.code === arc.code))
})

export const selectAnalysisRequestCategoryByCode = (
  state: RootState,
  code: string
) => {
  return selectObjectByAttr<AnalysisRequestCategory>(
    state,
    ANALYSIS_REQUEST_CATEGORY_NAMESPACE,
    PrivacyDomain.Public,
    "code",
    code
  );
};

export const selectAnalysisResultGroupIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisResultGroup> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_RESULT_GROUP_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};

export const selectAnalysisResultGroupObjects = createSelector(
  [selectAnalysisResultGroupIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisResultGroup>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectDefaultAnalysisResultGroupObjects = createSelector(
  [
    selectAnalysisResultGroupObjects,
  ],
  (objects) => {
    const resultGroups: StoreObject<AnalysisResultGroup>[] = objects.reduce(
      (resultGroups, obj) => {
        if (obj.data.ref.patient === undefined) {
          resultGroups.push(obj);
        }

        return resultGroups;
      },
      [] as StoreObject<AnalysisResultGroup>[]
    );

    return resultGroups;
  }
);

export const selectAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    selectDefaultAnalysisResultGroupObjects,
    selectAnalysisResultGroupObjects,
    (state: RootState, inlogId: string) => inlogId,
  ],
  (defaultGroups, allGroups, inlogId) => {

    // On créait un index des groupes à partir des groupes par défaut existants
    const groupIndex: { [code: string]: StoreObject<AnalysisResultGroup> } = {};
    defaultGroups.forEach(group => groupIndex[group.data.code] = group);

    // On surcharge/complète l'index avec chaque groupe existant pour l'inlogId donné
    allGroups.forEach(group => {
      if (group.data.ref.patient?.inlogId !== inlogId) {
        return
      }
      groupIndex[group.data.code] = group;
    })

    return Object.values(groupIndex)
  }
);

export const selectIndexedByGroupPunctualAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    selectDefaultAnalysisResultGroupObjects,
    selectAnalysisResultGroupObjects,
    (state: RootState, inlogId: string) => inlogId,
  ],
  (defaultGroups, allGroups, inlogId): { [code: string]: StoreObject<AnalysisResultGroup> } => {

    // On créait un index des groupes à partir des groupes par défaut existants, on filtre les groupes pour récupérer ceux liés au résultat ponctuel
    const groupCode: { [code: string]: StoreObject<AnalysisResultGroup> } = {};
    defaultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.Punctual
    ).forEach(group => groupCode[group.data.code] = group)

    // On surcharge/complète l'index avec chaque groupe existant pour l'inlogId donné, on récupère uniquement les groupes type "ponctuel"
    allGroups.forEach(group => {
      if (group.data.ref.patient?.inlogId !== inlogId || group.data.type != AnalysisResultGroupType.Punctual) {
        return
      }
      groupCode[group.data.code] = group;
    })
    return groupCode

  }
);


export const selectPunctualAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.Punctual
    );
  }
);

export const selectBinaryAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.Binary
    );
  }
);

export const selectDSA1AnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.DSA_CLASS1
    );
  }
);

export const selectDSA2AnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.DSA_CLASS2
    );
  }
);

export const selectHistorizedAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.Historized
    );
  }
);

export const selectCrossmatchAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupObjectsByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.data.type === AnalysisResultGroupType.Historized
    );
  }
);

export const selectAnalysisResultGroups = createSelector(
  [selectAnalysisResultGroupObjects],
  (objects) => {
    return objects.map((obj) => obj.data);
  }
);

export const selectAnalysisRequestCodeIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequestCode> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_CODE_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};

export const selectAnalysisRequestCodeObjects = createSelector(
  [selectAnalysisRequestCodeIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisRequestCode>[] = [];
    if (!objects) return items;
    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);


export const selectAnalysisRequestCodeByReagentAndCategory = (
  state: RootState, reagent: string | null, category: string | null
): AnalysisRequestCode[] | undefined => {
  const objectsById = state.objects.byNamespace[ANALYSIS_REQUEST_CODE_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;

  if (objectsById && reagent) {
    const items: StoreObject<AnalysisRequestCode>[] = [];
    Object.values(objectsById).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
    const objectsFilter = Object.values(items).filter((obj) => {
      if (category && category.length > 0) {
        return obj.data.ref.analysisReagentProvider.code === reagent && obj.data.ref.analysisRequestCategory.code === category;
      }
      return obj.data.ref.analysisReagentProvider.code === reagent
    });

    const analysisRequestCode: AnalysisRequestCode[] = [];

    return Object.values(objectsFilter).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj.data);
      return memo;
    }, analysisRequestCode);
  }


  return undefined;
};

export const selectAnalysisRequestCodeByReagent = createSelector(
  [
    selectAnalysisRequestCodeObjects,
    (state: RootState, reagentProviderCode: string) => reagentProviderCode
  ],
  (analysisRequestCodes, reagentProviderCode) => {
    return analysisRequestCodes.reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      if (obj.data.ref.analysisReagentProvider.code === reagentProviderCode || !reagentProviderCode) {
        memo.push(obj.data);
      }
      return memo;
    }, [] as AnalysisRequestCode[]);

  }
)

export const selectPendingAnalysisObjectsRequests = createSelector(
  [selectAnalysisRequestObjects],
  (analysisRequests) => {
    const analysisRequest: StoreObject<AnalysisRequest>[] = [];
    return analysisRequests.reduce((memo, obj) => {
      const analysisRequestCodes = Object.keys(
        obj.data.types
      ) as string[];
      const isPending =
        analysisRequestCodes.some(
          (code) => obj.data.types[code] === AnalysisRequestState.TakenIntoAccount
        )
      if (!isPending) return memo;

      memo.push(obj);

      return memo;
    }, analysisRequest);
  }
);

export const selectPendingAnalysisRequestsByReagent = createSelector(
  [
    selectTakenIntoAccountAnalysisRequests,
    (state: RootState, reagentProvider: string) => reagentProvider,
    selectAnalysisRequestCodeByReagent,
  ],
  (pendingAnalysisRequests, reagentProvider, analysisRequestCodes) => {
    return pendingAnalysisRequests.filter(analysisRequest => {
      return Object.keys(analysisRequest.types).some(code => {
        const analysisRequestCode = analysisRequestCodes?.find(arc => arc.code === code);
        if (!analysisRequestCode) return false;
        return analysisRequestCode.ref.analysisReagentProvider.code === reagentProvider
      })
    })
  }
)

export const selectAnalysisRequestByWorklist = createSelector(
  [
    selectAnalysisRequests,
    (state: RootState, worklist: WorklistV2) => worklist
  ],
  (analysisRequests, worklist) => {
    if (!analysisRequests) return [];

    const filtered = new Set<AnalysisRequest>();

    worklist.selections.forEach(s => {
      const found = analysisRequests.find(r => {
        const matches = s.ref.analysisRequest.executorSampleId === r.executorSampleId &&
          s.ref.analysisRequest.requestorSampleId === r.requestorSampleId &&
          s.ref.analysisRequest.requestDate === r.requestDate &&
          s.ref.analysisRequest.samplingEndDate === r.samplingEndDate &&
          s.ref.analysisRequest.samplingStartDate === r.samplingStartDate

        return matches
      })

      if (!found) return;

      filtered.add(found);
    })

    return Array.from(filtered);
  }
)

export const selectHandledAnalysisRequestObjects = createSelector(
  [selectAnalysisRequestObjects, selectAnalysisRequestCodeObjects],
  (analysisRequests, requestCodes) => {

    const codes = new Set<string>();
    requestCodes.forEach(rc => codes.add(rc.data.code));

    return analysisRequests.filter(ar => {
      const isHandled = Object.keys(ar.data.types).some(c => codes.has(c))
      console.log(`${ar.data.requestorSampleId}: ${JSON.stringify(ar.data.types)}: ${isHandled}`);
      return isHandled;
    })
  }
);
