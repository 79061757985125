import { Action } from "redux"
import { ObjectFilter, QueryObjectsOptions } from "../util/objects"
import { EventObject } from "../util/events"

export const EVENT_REQUEST = 'EVENT_REQUEST'
export const EVENT_REQUEST_SUCCESS = 'EVENT_REQUEST_SUCCESS'
export const EVENT_REQUEST_FAILURE = 'EVENT_REQUEST_FAILURE'

export interface FetchEventsOptions extends QueryObjectsOptions {
  types?: string[]
}

export interface fetchEventsAction extends Action {
  options?: FetchEventsOptions
  filter?: ObjectFilter
}

export interface FetchEventsSuccessAction extends Action {
  total: number,
  events: EventObject[]
}

export function fetchEvents(filter?: ObjectFilter, options?: FetchEventsOptions): fetchEventsAction {
  return {
    type: EVENT_REQUEST,
    options,
    filter
  }
};
