export const Config = {
  debug: get<boolean>('debug', false),
  reduxLogger: get<boolean>('reduxLogger', false),
  basePath: get<string>('basePath', '/'),
  appVersion: get<string>('appVersion', ''),
  pipelineServerURL: get<string>('pipelineServerURL', 'http://localhost:9000'),
  recordServerURL: get<string>('recordServerURL', 'http://localhost:9001'),
  converterServerURL: get<string>('converterServerURL', 'http://localhost:9002'),
  objectServerURL: get<string>('objectServerURL', 'http://localhost:9003'),
  authzServerURL: get<string>('authzServerURL', 'http://localhost:9004'),
  fileServerURL: get<string>('fileServerURL', 'http://localhost:9005'),
  eventServerURL: get<string>('eventServerURL', 'http://localhost:9006'),
  oidcClientID: get<string>('oidcClientID', 'sheila'),
  oidcBaseURL: get<string>('oidcBaseURL', 'https://sheila.cadol.es/auth'),
  oidcLogoutURL: get<string>('oidcLogoutURL', 'https://sheila.cadol.es/auth/oauth2/sessions/logout'),
  oidcPostLoginRedirectURL: get<string>('oidcPostLoginRedirectURL', 'http://localhost:8000/callback'),
  oidcPostLogoutRedirectURL: get<string>('oidcPostLogoutRedirectURL', 'http://localhost:8000/'),
  maxEntriesPerWorklist: get<number>('maxEntriesPerWorklist', 96),
  sentryDSN: get<string>('sentryDSN', ''),
  sentryEnvironment: get<string>('sentryEnvironment', window.location.host),
  sentryTracesSampleRate: get<number>('sentryTracesSampleRate', 0.2),
  // Code(s) d'examen associé(s) à la synthèse
  hlaSynthesisAnalysisRequestCodes: get<string[]>('hlaSynthesisAnalysisRequestCode', ['GRCRIS'])
}

function get<T>(key: string, defaultValue: T): T {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const config = Object.assign({}, process.env || {}, window.__CONFIG__ || {})

  if (config?.[key] !== undefined) {
    return config[key] as T
  } else {
    return defaultValue
  }
}
