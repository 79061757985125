import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import { MAIN_DRAWER_REQUEST } from '../actions/drawer'
import { toggleMainDrawerSaga } from './drawer'
import { sessionSaga } from './session'
import { SYNCHRO_FUSION_REQUEST } from '../actions/importer'
import { synchroFusionSaga } from './importer'
import { EXPORT_CRISTAL_REQUEST, EXPORT_LUMINEX_REQUEST } from '../actions/exporter'
import { exportCristalDataSaga, exportLuminexDataSaga } from './exporter'
import { failureSaga } from './failure'
import { Action } from 'redux'
import { objectsSaga } from './objects'
import { workListsSaga } from './worklists'
import { rehydrateSaga } from './rehydrate'
import { REHYDRATE } from 'redux-persist'
import { USER_LOGIN_CHECK_SUCCESS } from '../actions/session'
import { searchSaga } from './search'
import { tenantsSaga } from './tenants'
import { auditSaga } from './audit'
import { authzSaga } from './authz'
import { pipelineSaga } from './pipeline'
import { analysisRequestSaga } from './analysisRequest'
import { eventsSaga } from './events'
import { hlaSynthesisSaga } from './hla-synthesis'

export default function* rootSaga(): Generator {
  yield all([
    takeEvery(USER_LOGIN_CHECK_SUCCESS, rehydrateSaga),
    takeEvery(REHYDRATE, rehydrateSaga),
    takeEvery(MAIN_DRAWER_REQUEST, toggleMainDrawerSaga),
    takeEvery(SYNCHRO_FUSION_REQUEST, synchroFusionSaga),
    takeEvery(EXPORT_CRISTAL_REQUEST, exportCristalDataSaga),
    takeEvery(EXPORT_LUMINEX_REQUEST, exportLuminexDataSaga),
    objectsSaga(),
    workListsSaga(),
    sessionSaga(),
    searchSaga(),
    tenantsSaga(),
    auditSaga(),
    authzSaga(),
    pipelineSaga(),
    analysisRequestSaga(),
    eventsSaga(),
    failureSaga(),
    hlaSynthesisSaga(),
  ])
}
