import { ErrorBoundary } from "@sentry/react";
import React, { FunctionComponent, Suspense, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./components/Common/PrivateRoute";
import { Loader } from "./components/Loader/Loader";
import { history } from "./util/history";

const LazyHomePage = React.lazy(
  async () =>
    await import(/* webpackChunkName: "HomePage" */ "./pages/HomePage/HomePage")
);
const LazyNotFoundPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "NotFoundPage" */ "./pages/NotFoundPage/NotFoundPage"
    )
);
const LazyLoginPage = React.lazy(
  async () =>
    await import(/* webpackChunkName: "LoginPage" */ "./components/Auth/Login")
);
const LazyLogoutPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "LogoutPage" */ "./components/Auth/Logout"
    )
);
const LazyLoginCallback = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "LoginCallbackPage" */ "./components/Auth/LoginCallback"
    )
);
const LazyPatientPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "PatientPage" */ "./pages/Patient/PatientPage"
    )
);

const LazyWorkListDashboardPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "WorkListDashboardPage" */ "./pages/WorkList/WorkListDashboardPage"
    )
);

const LazyWorkListEditPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "WorkListEditPage" */ "./pages/WorkList/WorkListEditPage"
    )
);

const LazyCrossmatchPatientSelectorPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "CrossmatchPatientSelectorPage" */ "./pages/Crossmatch/CrossmatchPatientSelectorPage"
    )
);

const LazyCrossmatchPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "CrossmatchPage" */ "./pages/Crossmatch/CrossmatchPage"
    )
);

const LazyAuditPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "AuditPage" */ "./pages/AuditPage/AuditPage"
    )
);

const LazyErrorPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "LazyErrorPage" */ "./pages/ErrorPage/ErrorPage"
    )
);

const LazyExamDashboardPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "LazyExamDashboard" */ "./pages/ExamDashboard/ExamDashboard"
    )
);

const LazySettingsPage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "SettingsPage" */ "./pages/Settings/SettingsPage"
    )
);

const LazyTimelinePage = React.lazy(
  async () =>
    await import(
      /* webpackChunkName: "TimelinePage" */ "./pages/Timeline/TimelinePage"
    )
);

export const App: FunctionComponent = () => {
  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <ErrorBoundary fallback={<LazyErrorPage />}>
            <Switch>
              <PrivateRoute path="/" exact component={LazyHomePage} />
              <Route path="/callback" exact component={LazyLoginCallback} />
              <Route path="/login" exact component={LazyLoginPage} />
              <Route path="/logout" exact component={LazyLogoutPage} />
              <PrivateRoute
                path="/patient/:inlogID"
                exact
                component={LazyPatientPage}
              />
              <PrivateRoute path="/patient" exact component={LazyHomePage} />
              {/* WorkLists */}
              <PrivateRoute
                path="/worklists"
                exact
                component={LazyWorkListDashboardPage}
              />
              <PrivateRoute
                path="/worklists/:worklistId"
                exact
                component={LazyWorkListEditPage}
              />
              {/* ExamDashboard */}
              <PrivateRoute
                path="/exam"
                exact
                component={LazyExamDashboardPage}
              />

              {/* Crossmatch */}
              <PrivateRoute
                path="/crossmatch"
                exact
                component={LazyCrossmatchPatientSelectorPage}
              />
              <PrivateRoute
                path="/crossmatch/:inlogID"
                exact
                component={LazyCrossmatchPage}
              />

              {/* Visualisation des logs de décision */}
              <PrivateRoute path="/audit" exact component={LazyAuditPage} />

              {/* Paramétrages */}
              <PrivateRoute path="/settings" component={LazySettingsPage} />

              {/* Timeline */}
              <PrivateRoute path="/timeline" component={LazyTimelinePage} />

              <Route component={LazyNotFoundPage} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </Suspense>
    </Fragment>
  );
};
