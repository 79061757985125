import { EVENT_REQUEST_SUCCESS, FetchEventsSuccessAction } from "../actions/events";
import { Action } from "redux";
import { EventObject } from "../util/events";

export interface EventsState {
  total: number
  byId: { [id: string]: EventObject; }
}

const defaultState = {
  total: 0,
  byId: {},
};

export function eventsReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case EVENT_REQUEST_SUCCESS:
      return handleRequestSucess(state, action as FetchEventsSuccessAction)
    default:
      return state;
  }
};

function handleRequestSucess(state: EventsState, action: FetchEventsSuccessAction) {
  const events = action.events
  const indexedEvents = [...events].reduce((acc, event: EventObject) => {
    acc[event.id] = event;
    return acc;
  }, {} as EventsState);

  return {
    ...state,
    total: action.total,
    byId: {
      ...indexedEvents
    },
  }

}
