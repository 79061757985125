import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { INITIALIZE_HLA_SYNTHESIS_REQUEST, InitializeHLASynthesisRequestAction, updateHLASynthesis, INITIALIZE_HLA_SYNTHESIS_SUCCESS, INITIALIZE_HLA_SYNTHESIS_FAILURE } from '../actions/hla-synthesis';
import { HLASynthesis } from "../types/shared/v2/HLASynthesis";
import { selectUserName } from "../selectors/session";
import { Config } from "../config";
import { handleUnauthorizedRequestError } from "./helper";


export function* hlaSynthesisSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(INITIALIZE_HLA_SYNTHESIS_REQUEST, initializeHLASynthesisSaga)
  ]);
}

function* initializeHLASynthesisSaga(action: InitializeHLASynthesisRequestAction): Generator<any, any, any> {
  const username = yield select(selectUserName)

  const updates: Partial<HLASynthesis> = {};
  const now = new Date().toJSON();

  const classInterpretations = action.interpretations[action.hlaSynthesis.data.ref.hlaResult.class];

  try {
    Object.keys(classInterpretations).forEach((beadId) => {
      if (action.hlaSynthesis.data.beads[beadId]) return;
      if (!classInterpretations[beadId].synthesis?.data.code) return;

      updates.beads = {
        ...(updates.beads ?? {}),
        [beadId]: {
          resultGroup: {
            code: classInterpretations[beadId].synthesis?.data.code,
            patient: classInterpretations[beadId].synthesis?.data.ref
              .patient
              ? {
                inlogId:
                  classInterpretations[beadId].synthesis?.data.ref
                    .patient.inlogId,
              }
              : undefined,
          },
          modifiedAt: now,
          modifiedBy: username,
        },
      };
    });

    // Injection des codes d'examen associés à l'export de la synthèse
    if (
      !action.hlaSynthesis.data.ref.analysisRequestCodes ||
      action.hlaSynthesis.data.ref.analysisRequestCodes.length === 0
    ) {
      updates.ref = {
        ...action.hlaSynthesis.data.ref,
        analysisRequestCodes: Config.hlaSynthesisAnalysisRequestCodes.map(
          (code) => ({ code })
        ),
      };
    }

    if (Object.keys(updates).length !== 0) {
      yield put(updateHLASynthesis(action.hlaSynthesis.id, updates, true))
    }


  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedRequestError, error, action)
    if (handled) return

    yield put({ type: INITIALIZE_HLA_SYNTHESIS_FAILURE, txId: (action as any).txId, error })
    return
  }

  yield put({ type: INITIALIZE_HLA_SYNTHESIS_SUCCESS, txId: (action as any).txId })
}
