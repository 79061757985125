import { Action } from 'redux-saga';
import { HLAResultRef } from '../types/shared/v2/HLAResult';
import { HLAResultDecision } from '../types/shared/v2/HLAResultDecision';
import { HLASynthesis } from '../types/shared/v2/HLASynthesis';
import { Interpretations } from '../util/hla-result';
import { ObjectFilter, PrivacyDomain, QueryObjectsOptions, StoreObject } from "../util/objects"
import { fetchObject, queryObjects, updateObject } from "./objects"

export const HLA_SYNTHESIS_NAMESPACE = 'hla-synthesis-v1'

export function fetchPublicHLASynthesis(objectId: string) {
  return fetchObject(HLA_SYNTHESIS_NAMESPACE, PrivacyDomain.Public, objectId)
}

export function queryPublicHLASynthesis(filter?: ObjectFilter, options?: QueryObjectsOptions) {
  return queryObjects(HLA_SYNTHESIS_NAMESPACE, PrivacyDomain.Public, filter, options)
}

export function queryHLASynthesisByHLAResult(ref: HLAResultRef) {
  return queryObjects(
    HLA_SYNTHESIS_NAMESPACE, PrivacyDomain.Public,
    {
      eq: Object.keys(ref).reduce((criteria, key) => {
        criteria[`ref.hlaResult.${key}`] = ref[key];
        return criteria;
      }, {})
    },
    { hydrate: true }
  )
}

export function updateHLASynthesis(objectId: string, hlaSynthesis: Partial<HLASynthesis>, partial: boolean = false) {
  return updateObject<HLASynthesis>(HLA_SYNTHESIS_NAMESPACE, PrivacyDomain.Public, objectId, hlaSynthesis, partial)
}

export const INITIALIZE_HLA_SYNTHESIS_REQUEST = 'INITIALIZE_HLA_SYNTHESIS_REQUEST';
export const INITIALIZE_HLA_SYNTHESIS_SUCCESS = 'INITIALIZE_HLA_SYNTHESIS_SUCCESS';
export const INITIALIZE_HLA_SYNTHESIS_FAILURE = 'INITIALIZE_HLA_SYNTHESIS_FAILURE';

export interface InitializeHLASynthesisRequestAction extends Action {
  hlaSynthesis: StoreObject<HLASynthesis>,
  interpretations: Interpretations
}

export function initializeHLASynthesis(hlaSynthesis: StoreObject<HLASynthesis>, interpretations: Interpretations) {
  return { type: INITIALIZE_HLA_SYNTHESIS_REQUEST, hlaSynthesis, interpretations }
}